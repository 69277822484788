import React, { useCallback, useReducer, useMemo, useEffect } from 'react';
import './App.scss';

import Game from './Game';
import { initBoard, calculateBoardAfterMove, selectCPmoves } from './gameLogic';

const gameModes = ['2p', '1p']; // 'network'

const initGame = {
  chips: [...initBoard],
  whiteHome: 0,
  whiteJail: 0,
  blackHome: 0,
  blackJail: 0,

  turn: null,
  waitingForRoll: true,
  dice: [],
  selectedChip: null,

  mode: '1p',
  computerPlays: 'white',
  waitingForCP: false,
  cpMoves: [],
};

const randomDice = ()=> {
  const dice = [ Math.ceil(Math.random()*6), Math.ceil(Math.random()*6)];
  return ( dice[0] !== dice[1] ) ? dice : [...dice, ...dice];
};

const differentDice = ()=>{
  let dice = [];
  while(!dice.length || (dice[0] === dice[1])) dice = randomDice();
  return dice;
};

const gameReducers = {
  selectChip: (state, action)=> (
    ((state.mode === '1p') && (state.turn === state.computerPlays)) ? (
      state
    ) : ({ ...state, selectedChip: action.payload })
  ),
  unselectChip: state=> ({ ...state, selectedChip: null }),
  
  setDice: (state, action)=> ({
    ...state,
    dice: action.payload,
    waitingForRoll: false,
  }),

  cpRoll: (state, action)=> ({
    ...state,
    dice: action.payload,
    waitingForRoll: false,
    waitingForCP: true,
  }),
  
  makeMove: (state, action)=> (
    ((state.mode === '1p') && (state.turn === state.computerPlays)) ? (
      state
    ) : ({
      ...state,
      ...calculateBoardAfterMove(state, action.payload),
      selectedChip: null,
    })
  ),
  cpMakeMove: (state, action)=> (
    ((state.mode === '1p') && (state.turn !== state.computerPlays)) ? (
      state
    ) : ({
      ...state,
      ...calculateBoardAfterMove(state, state.cpMoves[0]),
      cpMoves: state.cpMoves.slice(1),
      selectedChip: null,
      waitingForCP: false,
    })
  ),

  cpSelectMoves: (state, action)=> ({
    ...state,
    cpMoves: selectCPmoves(state),
  }),

  endTurn: (state, action)=> ({
    ...state,
    turn: !state.turn ? state.turn : state.turn === 'white' ? 'black' : 'white',
    dice: [],
    waitingForRoll: true,
    waitingForCP: (state.mode === '1p') && (state.turn !== state.computerPlays),
  }),
  startGame: (state, { payload: dice }={})=> ({
      ...state,
      dice,
      turn: dice[0] > dice[1] ? 'black' : 'white',
      waitingForRoll: false,
      waitingForCP: (state.mode === '1p') && (
        (dice[0] > dice[1] ? 'black' : 'white') === state.computerPlays
      )
  }),
  restartGame: () => initGame,

  changeGameMode: (state)=> ({
    ...state,
    mode: gameModes[ (gameModes.indexOf(state.mode) + 1) % gameModes.length ],
  }),
};

const gameReducer = (state, action)=> (gameReducers[action.type] || (i=> i))(state, action);

const actions = dispatch=>
  Object.keys(gameReducers)
        .reduce((reducers, type)=> ({
          ...reducers,
          [type]: payload=> dispatch({ type, payload })
        }), {});


const App = ()=> {
  const [game, dispatch] = useReducer(gameReducer, initGame);

  const {
    selectChip,
    unselectChip,
    setDice,
    makeMove,
    endTurn,
    startGame,
    restartGame,
    changeGameMode,
    cpMakeMove,
    cpSelectMoves,
    cpRoll,
  } = useMemo(()=> actions(dispatch), [dispatch]);

  const roll = useCallback(()=> (
    game.turn ?
    game.dice.length ?
    null :
    setDice( randomDice() ) :
    startGame(differentDice())
  ), [game.dice.length, game.turn, setDice, startGame]);

  useEffect(()=> void (
    Math.max(game.whiteHome, game.blackHome) === 15 ? restartGame() : null
  ), [game.whiteHome, game.blackHome, restartGame]);

  useEffect(()=> {
    if(game.waitingForCP && game.dice.length) cpSelectMoves();
  }, [cpSelectMoves, game.waitingForCP, game.dice]);

  useEffect(()=> {
    if(game.cpMoves.length) setTimeout(()=> cpMakeMove(), 800);
  }, [game.cpMoves, cpMakeMove]);

  useEffect(()=> {
    if(( game.mode === '1p' ) && (game.turn === game.computerPlays) && game.waitingForRoll) {
      // trigger computer to roll, make move
      cpRoll( randomDice() );
    }
  }, [game.dice, game.mode, game.turn, game.computerPlays, game.waitingForRoll, cpRoll]);

  return (
    <div className='App'>
      <Game
        selectChip={selectChip}
        unselectChip={unselectChip}
        makeMove={makeMove}
        roll={roll}
        endTurn={endTurn}
        changeGameMode={changeGameMode}
        {...game}
      />
    </div>
  );
};

export default App;
