import React, { useCallback, useMemo, useEffect } from 'react';

import Board from './Board';
import Dice from './Dice';

import { calculateLegalMoves } from './gameLogic';

const Game = ({
  endTurn,
  
  selectChip,
  unselectChip,
  makeMove,

  selectedChip,
  
  roll,
  dice,
  
  turn,
  chips,
  
  blackJail,
  whiteJail,
  blackHome,
  whiteHome,

  waitingForRoll,

  mode,
  changeGameMode,
})=> {
  
  const legalMoves = useMemo(()=> calculateLegalMoves({
    dice,
    turn,
    chips,
    blackJail,
    whiteJail,
  }).filter(move=> (selectedChip === null) || move.moveFrom === selectedChip), [
    selectedChip,
    dice,
    turn,
    chips,
    blackJail,
    whiteJail,
  ]);

  const chipClicked = useCallback((clicked)=>{
    // if no dice, do nothing (wait for roll)
    if( !dice.length ) return;

    // if player is in jail
    if( (turn === 'black' && blackJail) || (turn === 'white' && whiteJail) ){
      const clickMove = legalMoves.find(({ moveFrom, moveTo }) => (
        (moveFrom === turn + 'Jail') &&
        (moveTo === clicked)
      ));
      
      if( clickMove ) makeMove(clickMove);
      
    } else {
      // if no chip selected
      if( selectedChip === null ){
        // if click is on turn's chips with legal moves, select that chip (return)
        if( legalMoves.filter(({ moveFrom }) => moveFrom === clicked ).length )
          selectChip(clicked);
        
      } else {
        // else this is a second click
        const clickMove = legalMoves.find(({ moveFrom, moveTo }) => (
          (moveFrom === selectedChip) &&
          (moveTo === clicked)
        ));
        
        if( clickMove ) makeMove(clickMove);


        // if another click on the selectedChip, unselect the chip
        if( clicked === selectedChip ) unselectChip();
      }
    }
  }, [
    dice,
    selectedChip,
    blackJail,
    whiteJail,
    turn,
    selectChip,
    unselectChip,
    legalMoves,
    makeMove,
  ]);

  const chipDoubleClicked = useCallback((clicked)=>{
    const moveIfLegal = legalMoves.find(move=> move.moveFrom === clicked && move.moveTo.includes?.('Home'));
    if( moveIfLegal ) makeMove(moveIfLegal);
    
  }, [legalMoves, makeMove]);

  useEffect(()=> void (
    !legalMoves.length && !waitingForRoll ? setTimeout(endTurn, dice.length * 1000) : null
  ), [endTurn, legalMoves, dice, waitingForRoll]);

  const highlitMoves = useMemo(()=> (
    (selectedChip === null) ? [
      ...legalMoves.filter(move => move.moveFrom.includes?.('Jail')).map(move => move.moveTo),
      ...legalMoves.filter(move => move.moveTo.includes?.('Home')).map(move => move.moveFrom),
    ] : (
      legalMoves.filter(move => move.moveFrom === selectedChip).map(move => move.moveTo)
    )
  ), [legalMoves, selectedChip]);
  
  return (
    <div className='game-container'>
      <Board
        onClick={chipClicked}
        onDoubleClick={chipDoubleClicked}
        whiteHome={whiteHome}
        blackHome={blackHome}
        whiteJail={whiteJail}
        blackJail={blackJail}
        chips={chips}
        selectedChip={selectedChip}
        highlitMoves={highlitMoves}
      />

      <div className='dice-container'>
        {!dice.length && waitingForRoll ? (
          <button onClick={roll}>roll</button>
        ) : (
          <Dice dice={dice} turn={turn} />
        )}
      </div>

      
      {
        !turn && (
          <div className='game-mode-container'>
            <button onClick={changeGameMode}>{mode}</button>
          </div>
        )
      }
    </div>
  );
};

export default Game;
